import { useEffect } from "react"

function useScroll(callback) {
  useEffect(() => {
    window.addEventListener("scroll", callback)
    return () => window.removeEventListener("scroll", callback)
  }, [callback])
}

export { useScroll }
