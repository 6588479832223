function generatePageSpecificBanners({ banners, bannerPage }) {
  const bannersObj = {
    headline: [],
    body: [],
    image: [],
    pageLink: [],
    pageLinkDesc: [],
  }
  switch (bannerPage) {
    case "services":
      banners.forEach(item => {
        const { services_banner_headline, services_banner_body, services_banner_image, services_banner_page_link, services_banner_page_link_description } = item
        bannersObj.headline.push(services_banner_headline)
        bannersObj.body.push(services_banner_body)
        bannersObj.image.push(services_banner_image)
        bannersObj.pageLink.push(services_banner_page_link)
        bannersObj.pageLinkDesc.push(services_banner_page_link_description)
      })
      break

    default:
      banners.forEach(item => {
        const { home_banner_headline, home_banner_body, home_banner_image, home_banner_page_link, home_banner_page_link_description } = item
        bannersObj.headline.push(home_banner_headline)
        bannersObj.body.push(home_banner_body)
        bannersObj.image.push(home_banner_image)
        bannersObj.pageLink.push(home_banner_page_link)
        bannersObj.pageLinkDesc.push(home_banner_page_link_description)
      })
      break
  }
  return bannersObj
}

export { generatePageSpecificBanners }
