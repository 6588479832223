import React from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { Banners } from "../components/Banners"
import { Footer } from "../components/Footer"

// Services page template
export default function TemplateServices(props) {
  const {
    data: {
      wordpressPage: {
        slug,
        acf: {
          services_headline: headline,
          services_banner: banner,
          services_hero_title: heroTitle,
          services_hero_copy: heroCopy,
          services_hero_left_image: heroLeftImage,
          services_hero_top_right_image: heroTopRightImage,
          services_hero_bottom_right_image: heroBottomRightImage,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  return (
    <section className="white services">
      <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />

      <Header headerHeadline={headline}>
        <div className="header__images-wrapper">
          <div className="header__images-container">
            <img
              className="header__hero-image header__hero-image--full"
              src={heroLeftImage.localFile.childImageSharp.fluid.src}
              alt="Robert Idol Design Services"
            />
          </div>
          <div className="header__images-container">
            <img
              className="header__hero-image header__hero-image--alt"
              src={heroTopRightImage.localFile.childImageSharp.fluid.src}
              alt="Robert Idol Design Services"
            />
            <img className="header__hero-image" src={heroBottomRightImage.localFile.childImageSharp.fluid.src} alt="Robert Idol Design Services" />
          </div>
        </div>
        <h3 className="header__title">{heroTitle}</h3>
        <p className="header__copy">{heroCopy}</p>
      </Header>

      <Banners banners={banner} bannerPage="services" reverseBanners={true} />

      <Footer
        companyName={companyName}
        companyTwitter={companyTwitter}
        companyLinkedIn={companyLinkedIn}
        slug={slug}
        menuItemsSorted={handleMenuItems({ items: edges })}
      />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "Services" }) {
      title
      slug
      content
      acf {
        services_headline
        services_hero_title
        services_hero_copy
        services_hero_left_image {
          source_url
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        services_hero_top_right_image {
          source_url
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        services_hero_bottom_right_image {
          source_url
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        services_banner {
          services_banner_headline
          services_banner_body
          services_banner_page_link
          services_banner_page_link_description
          services_banner_image {
            source_url
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
