import React from "react"
import { Rollover } from "../components/Rollover"
import { generatePageSpecificBanners } from "./generatePageSpecificBanners"

function generateBanners({ banners, bannerPage, isRefInViewport }) {
  function addActiveClasses() {
    if (isRefInViewport) {
      return ["banner__wrapper--active", "banner__wrapper-right--active"]
    }
    return ""
  }

  const bannersObj = generatePageSpecificBanners({
    banners,
    bannerPage,
  })

  return banners.map((item, idx) => (
    <div
      key={bannersObj.headline[idx]}
      className={`${idx % 2 === 0 ? `banner__wrapper ${addActiveClasses()[0]}` : `banner__wrapper-right ${addActiveClasses()[1]}`}`}>
      <div className="banner__container">
        <img
          className={`banner__image ${idx % 2 === 0 ? "banner__image--right" : ""}`}
          src={`${bannersObj.image[idx].localFile.childImageSharp.fluid.src}`}
          alt="Robert Idol Design Showcase Piece"
        />
        <div className="banner__content">
          <h2 className="banner__headline">{bannersObj.headline[idx]}</h2>
          <p className="banner__copy">{bannersObj.body[idx]}</p>
          <Rollover pageLink={bannersObj.pageLink[idx]} pageLinkCopy={bannersObj.pageLinkDesc[idx]} />
        </div>
      </div>
    </div>
  ))
}

export { generateBanners }
