import React, { useRef, useState } from "react"

// Custom Hooks
import { useScroll } from "../hooks/useScroll"

// Utility Functions
import { generateBanners } from "../utils/generateBanners"

function Banners(props) {
  const { banners, bannerPage, reverseBanners } = props
  const [isRefInViewport, setIsRefInViewport] = useState(false)
  const bannerEntryPoint = useRef(null)

  function checkRefPosition() {
    const windowSize = window.pageYOffset
    setIsRefInViewport(bannerEntryPoint.current.getBoundingClientRect().top <= windowSize / 2)
  }

  useScroll(checkRefPosition)

  return (
    <section className={`banner ${reverseBanners ? "banner-reverse" : ""}`} ref={bannerEntryPoint}>
      {generateBanners({
        banners,
        bannerPage,
        isRefInViewport,
      })}
    </section>
  )
}

export { Banners }
